export default function ConfirmServer(ajax, config) {
  const { domainName, ConfirmServer } = config

  return {
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    //下载确认单
    downloadConfirm: (opt) =>
      ajax({
        file: true,
        url: '/download-confirmation',
        method: 'get',
        responseType: 'blob',
        ...opt,
      }),
    // 删除行程单
    removeJourneyAttachment: (opt) =>
      ajax({
        url: '/remove-journey-attachment',
        method: 'post',
        ...opt,
      }),
    // 上传行程单
    uploadJourneyAttachment: (opt) =>
      ajax({
        url: '/upload-journey-attachment',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        ...opt,
      }),
    fileUpload: (opt) =>
      ajax({
        url: '/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        ...opt,
      }),
    // 操作记录
    operationLog: (opt) => ajax({ url: '/operation-log/list', method: 'get', ...opt }),
    logAdd: (opt) => ajax({ url: '/operation-log/add', method: 'POST', ...opt }),
    //确管所有列表
    list: (opt) =>
      ajax({
        url: '/query-list-information',
        method: 'POST',
        ...opt,
      }),

    // 详情-所有页面用到的详情
    detail: (opt) =>
      ajax({
        url: `/workform/${opt.id}`,
        method: 'get',
      }),
    // 根据多个id获取确认单列表
    getListByIds: (opt) =>
      ajax({
        url: `/batch-workforms-by-ids`,
        method: 'POST',
        ...opt,
      }),
    // 批量处理确认单
    batchConfirm: (opt) =>
      ajax({
        url: '/batch-confirm-revert',
        method: 'post',
        ...opt,
      }),
    // 批量导出出游人
    batchExportTour: (opt) =>
      ajax({
        file: true,
        responseType: 'blob',
        url: '/batch-export-tourist',
        method: 'get',
        ...opt,
      }),
    // 保存占位单、取消占位单处理
    occupyRevert: (opt) =>
      ajax({
        url: '/occupy-revert',
        method: 'POST',
        ...opt,
      }),

    // 保存确认单、取消确认单处理
    confirmRevert: (opt) =>
      ajax({
        url: '/confirm-revert',
        method: 'POST',
        ...opt,
      }),

    // 签证校验出游人送签状态
    confirmCheck: (opt) =>
      ajax({
        url: '/confirm-submit-check',
        method: 'POST',
        ...opt,
      }),

    // 保存核损单处理
    assessLossRevert: (opt) =>
      ajax({
        url: '/assess-loss-revert',
        method: 'POST',
        ...opt,
      }),

    // 添加确认单
    manualWorkform: (opt) =>
      ajax({
        url: '/manual-workform',
        method: 'POST',
        ...opt,
      }),
    // 确认提交确认单
    confirmSubmit: (opt) => ajax({ url: '/manual-workform/audition', method: 'POST', ...opt }),
    subWorkformDetail: (opt) =>
      ajax({
        url: `/sub-workform/${opt.id}`,
        method: 'get',
      }),
    delSubWork: (id) =>
      ajax({
        url: `/del-virtual-subWorkform/${id}`,
        method: 'DELETE',
      }),

    // 出团通知列表
    travelInformList: (opt) =>
      ajax({
        url: '/travel-inform/query-inform',
        method: 'GET',
        ...opt,
      }),

    // 发送出团通知
    saveInform: (opt) =>
      ajax({
        url: '/travel-inform/save-inform',
        method: 'POST',
        ...opt,
      }),

    // 出团通知附件详情
    queryInformDetail: (opt) =>
      ajax({
        url: `/travel-inform/query-inform-detail/${opt.id}`,
        method: 'GET',
      }),

    //删除上传文件
    fileRemove: (opt) =>
      ajax({
        url: '/remove',
        method: 'POST',
        ...opt,
      }),
    addSpecialRemark: (opt) => ajax({ url: '/workform/add-remark', method: 'POST', ...opt }),
    // 客人使用凭证列表
    attachmentList: (opt) =>
      ajax({
        url: `/certificate`,
        method: 'GET',
        ...opt,
      }),
    // 查看凭证回传详情
    attachmenDetail: (opt) =>
      ajax({
        url: `/certificate/${opt.id}`,
        method: 'GET',
        ...opt,
      }),
    // 处理凭证回传弹窗
    attachmentEdit: (opt) =>
      ajax({
        url: `/certificate`,
        method: 'POST',
        ...opt,
      }),

    attachmentUpload: (opt) =>
      ajax({
        url: `/certificate/upload`,
        method: 'POST',
        ...opt,
      }),
    addCertificate: (opt) =>
      ajax({
        url: `/workform/add-certificate`,
        method: 'POST',
        ...opt,
      }),

    attachmentRemove: (opt) =>
      ajax({
        url: `/certificate/remove`,
        method: 'POST',
        ...opt,
      }),
    addTeamWorkform: (opt) =>
      ajax({
        url: `/add-team-workform`,
        method: 'POST',
        ...opt,
      }),
    deleteConfirm: (id) =>
      ajax({
        url: `/del-workform/${id}`,
        method: 'delete',
      }),
    // 撤回待财务审核的单据
    recallConfirm: (opt) =>
      ajax({
        url: '/confirm/finance-audit-recall',
        method: 'POST',
        ...opt,
      }),
    // 查询团队确认单订单汇总
    getTeamOrderSum: (id) =>
      ajax({
        url: `/team-workform-order-sum/${id}`,
        method: 'get',
      }),
    getTeamMoneySum: (id) =>
      ajax({
        url: `/team-workform-confirm-sum/${id}`,
        method: 'get',
      }),
    downloadConfirmation: (opt) =>
      ajax({
        file: true,
        responseType: 'blob',
        url: `/download-confirmation`,
        method: 'get',
        ...opt,
      }),
    touristExport: (opt) =>
      ajax({
        file: true,
        responseType: 'blob',
        url: `/tourist/export`,
        method: 'get',
        ...opt,
      }),
    downloadTeamCom: (opt) =>
      ajax({
        file: true,
        responseType: 'blob',
        url: `/download-team-confirm-pdf`,
        method: 'get',
        ...opt,
      }),
    exportBill: (opt) =>
      ajax({
        file: true,
        responseType: 'blob',
        url: '/export-workform-reconciliation',
        method: 'get',
        timeout: 60000,
        ...opt,
      }),

    // 使用凭证列表-订单用
    ticketManagementPage: (opt) =>
      ajax({
        url: `/ticket-management/page`,
        method: 'get',
        ...opt,
      }),

    // 成本项
    costItemListByResourceType: (opt) =>
      ajax({
        url: '/wrapper/cost-item-by-resource-type/list',
        method: 'post',
        ...opt,
      }),
  }
}
