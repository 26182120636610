export default function VendorServer(ajax, config) {
  return {
    vendorList: (opt) => {
      return ajax({
        url: `/vendor-info`,
        method: 'get',
        ...opt,
      })
    },
    getVirtualVendorList: (opt) =>
      ajax({
        url: `/vendor-info/virtual-vendor/list`,
        method: 'post',
        ...opt,
      }),
    relationCompanies: (opt) => {
      return ajax({
        url: `/vendor-info/relation-companies/${opt}`,
        method: 'get',
      })
    },
    bankInfo: (opt) => ajax({ url: `/bank-info`, method: 'get', ...opt }),
    validVendorList: (opt) => {
      return ajax({
        url: `/vendor-info/valid_vendor_list`,
        method: 'get',
        ...opt,
      })
    },
    // 查询公司关联的客户属性的供应商信息
    customerList: (opt) => {
      return ajax({
        url: '/vendor-info/customer-list',
        method: 'get',
        ...opt,
      })
    },
    // 根据架构公司查询供应商列表
    getListByOrgCompanyId: (opt) => {
      return ajax({
        url: '/vendor-info/getListByOrgCompanyId',
        method: 'post',
        ...opt,
      })
    },
    userList: (opt) => {
      return ajax({
        url: `/user/list`,
        method: 'post',
        ...opt,
      })
    },

    // 注册账号接口
    userRegister: (opt) => {
      return ajax({
        url: `/user/register`,
        method: 'post',
        ...opt,
      })
    },
    // 编辑账号接口
    userUpdate: (opt) => {
      return ajax({
        url: `/user/update`,
        method: 'post',
        ...opt,
      })
    },
    // 重置账号密码接口
    userPwdReset: (opt) => {
      return ajax({
        url: `/user/reset/password`,
        method: 'post',
        ...opt,
      })
    },
    // 查询该账号是否重复
    checkRepeatAccount: (opt) => {
      return ajax({
        url: `/user/checkUserNameAvailable`,
        method: 'post',
        ...opt,
      })
    },
    // 启用或停止账号接口
    userUpdateStatus: (opt) => {
      return ajax({
        url: `/user/update/status`,
        method: 'post',
        ...opt,
      })
    },

    userOperationList: (opt) => {
      return ajax({
        url: `/user/operation/list`,
        method: 'post',
        ...opt,
      })
    },

    // 枚举值公共接口
    enumValues: (opt) =>
      ajax({
        url: `/enum/values?name=${opt.name}`,
        method: 'GET',
        ...opt,
      }),
    // 查询用户信息
    getUserDetail: (opt) =>
      ajax({
        url: `/user/get-user-detail`,
        method: 'GET',
        ...opt,
      }),
    // 通过用户id查询采购公司及部门
    getUserCompanyAndDepartment: (opt) =>
      ajax({
        url: `/user/get-scope-cooperation-list`,
        method: 'POST',
        ...opt,
      }),
    // 通过供应商账号id和架构公司id查询采购公司
    getCompanyListByOrgCompany: (opt) =>
      ajax({
        url: `/user/queryCorpListByOrgCompanyId`,
        method: 'POST',
        ...opt,
      }),
    // 获取所有cop菜单列表
    getVendorMenu: (opt) =>
      ajax({
        url: `/menu/get-all-menu `,
        method: 'GET',
        ...opt,
      }),
    queryCurrency: (opt) =>
      ajax({
        url: `/financial-info/query-currencyCode-merchantProperty`,
        method: 'get',
        ...opt,
      }),

    getVendorResource: (opt) =>
      ajax({
        url: `/vendor-resource/getByOrgCompanyId/${opt.orgCompanyId}`,
        method: 'get',
        ...opt,
      }),
    // 查询供应商用户组别列表
    queryUserGroup: (opt) =>
      ajax({
        url: `/vendor-user-group/list-group`,
        method: 'POST',
        ...opt,
      }),
    // 新增供应商用户组别列表
    addUserGroup: (opt) =>
      ajax({
        url: `/vendor-user-group/add`,
        method: 'POST',
        ...opt,
      }),
    // 查询客商等级
    queryTraderLevel: (opt) =>
      ajax({
        url: `/level-config/list`,
        method: 'post',
        ...opt,
      }),
  }
}
